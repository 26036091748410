<script setup>

defineProps({
    sm: Boolean, //icon size 24
    md: Boolean, //icon size 32
    normal: Boolean, //icon size 40
    lg: Boolean, //icon size 48
    xl: Boolean, //icon size 56
    xxl: Boolean, //icon size 64
    profilePhoto: String,
});

const avatarImageErrorFallback = (event) => {
    event.target.src = '/storage/icons/user-01.svg';
}

</script>

<template>
    <img class="rounded-full bg-gray-100 object-cover" :src="profilePhoto ? profilePhoto : '/storage/icons/user-01.svg'" alt="avatar"
         :class="[sm ? ['w-6','h-6'] : ''
            ,md ? ['w-8','h-8'] : ''
            ,normal ? ['w-10','h-10'] : ''
            ,lg ? ['w-12','h-12'] : ''
            ,xl ? ['w-14','h-14'] : ''
            ,xxl ? ['w-16','h-16'] : ''
            ,!profilePhoto ? ['p-1'] : '']"
         @error="avatarImageErrorFallback"
    />
</template>

<style scoped>

</style>
